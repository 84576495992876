<template>
  <div class="user-index">
    <Search></Search>
    <div class="u-container clearfix">
      <div class="u-aside">
        <div class="a-hd">
          <div class="h-img">
            <img :src="user_avatar" alt="" />
          </div>
          <span>{{ user_nickname }}</span>
        </div>
        <div class="a-bd">
          <div class="menus">
            <div
              v-for="(menu, index) in userMenu"
              :key="index"
              class="userMenu"
            >
              <router-link :to="menu.link">{{ menu.title }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <el-main>
        <transition name="el-zoom-in-center">
          <router-view @userNickname="userNickname" @userAvatar="userAvatar" />
        </transition>
      </el-main>
    </div>
  </div>
</template>

<script>
  import Search from '../../components/search.vue'
  export default {
    name: "user",
    components: {Search},
    data() {
      return {
        userMenu: [
        {
          key: 1,
          title: '账户管理',
          link: '/user/center'
        },
        {
          key: 2,
          title: '账户安全',
          link: '/user/safe'
        },
        {
          key: 3,
          title: '我的订单',
          link: '/user/orderList'
        },
        {
          key: 4,
          title: '我的优惠券',
          link: '/user/myCoupon'
        },
        {
          key: 5,
          title: '我的余额',
          link: '/user/balance'
        },
        {
          key: 6,
          title: '我的收藏',
          link: '/user/collect'
        },
        {
          key: 7,
          title: '地址管理',
          link: '/user/address'
        },
        { key: 8,
          title: '售后服务',
          link: '/user/afterSales/service'
          },
        {
          key: 9,
          title: '领取优惠券',
          link: '/user/Coupon'
        }],
        user_avatar: '',
        user_nickname: ''
      }
    },
    methods: {
      userNickname(val) {
        this.user_nickname = val
      },
      userAvatar(val) {
        this.user_avatar = val
        console.log(this.user_avatar, val)
      }
    }
  }
</script>
<style lang="less" scoped>
.user-index {
  background: #f9f9f9;
  padding: 20px 0px;

  .u-container {
    width: 1200px;
    margin: 0px auto;
    // border: 1px solid #e1251b;
    box-sizing: border-box;
    display: flex;

    .u-aside {
      width: 180px;
      min-height: 100vh;
      margin-right: 20px;
      box-sizing: border-box;

      .a-hd {
        height: 170px;
        margin-bottom: 25px;
        background-color: #fff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;

        .h-img {
          margin-bottom: 20px;

          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
        }
      }

      .a-bd {
        background-color: #fff;
        min-height: 80vh;

        .menus {
          padding-top: 40px;
        }

        .userMenu {
          margin-bottom: 30px;
          text-align: center;
          color: #666;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }

    .el-main {
      width: 1000px;
      min-height: 100vh;
      background-color: #fff;
      padding: 0px;
    }
  }
}
</style>